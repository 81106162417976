<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
      <section id="transaction-products">
        <div class="container">
  
          <h4 style="margin-bottom: 0px;" class="fw-bold text-primary">Transactions </h4>
          <!-- <div class="warehouse-inventory-card-wrapper">
            <div class="warehouse-inventory-card">
              <p>Unverified <br>
                Transactions</p>
              <strong class="red">0</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Stock-In <br>
                (Added)</p>
              <strong>0</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Stock-Out <br>
                (Removed)</p>
              <strong>0</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Total Stock <br>
                Transactions</p>
              <strong>0</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Split Receipt <br>
                Transactions </p>
              <strong>0</strong>
            </div>
  
            <div class="warehouse-inventory-card">
              <p>Saved <br>
                Transactions</p>
              <strong class="green">0</strong>
            </div>
  
          </div> -->
  
          <div class="filter-search" style="margin-top: 0px;padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo" />
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="name">Batch No</label>
                      <input type="text" class="form-control" v-model="batchNo" />
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Criteria</label>
                      <select class="full" v-model="criteria">
                        <option value="all">All</option>
                        <option value="with">With PO</option>
                        <option value="without">Without PO</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Status</label>
                      <select class="full" v-model="status">
                        <option value="all">All</option>
                        <option value="processed">Processed</option>
                        <option value="partial">Partial</option>
                        <option value="saved">Saved</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Verification</label>
                      <select class="full" v-model="verification">
                        <option value="all">All</option>
                        <option value="1">Verified</option>
                        <option value="0">Unverified</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Stock Category</label>
                      <select v-model="stockId" class="full" id="stockCategories"  v-if="nogs.length > 0">
                          <option value="0">All</option>
                          <option v-for="nog in nogs" v-bind:Key="nog.id" :value="nog.id">
                              {{ nog.nog }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No Stock Category Found.</h5> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Created By</label>
                      <select v-model="createdId" class="full" id="createdUsers"  v-if="users.length > 0">
                          <option value="0">Any</option>
                          <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                              {{ user.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No user found.</h5> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Verified By</label>
                      <select v-model="verifiedId" class="full" id="verifiedUsers"  v-if="users.length > 0">
                          <option value="0">Any</option>
                          <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                              {{ user.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No user found.</h5> 
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-primary small" style="margin-top: 32px;" type="button" @click="search">Search</button>
                  </div>
                  

                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list" style="margin-top: 0px;">

            <strong style="margin-top: 0px;">Transactions Table</strong>
            <!-- <keep-alive> -->
            <table id="transactionsTable" class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Date</th>
                <th>Reference No.</th>
                <th>PO No.</th>
                <th>Exception</th>
                <th>Status</th>
                <th>Verification</th>
                <th>Stock Category</th>
                <th>Handled By</th>
                <th>Action</th>
              </thead>
              
            </table>

            <LoaderItem v-if="loading"/>
           
          <!-- </keep-alive> 
            <div v-if="batches?.length < 1">
              <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block; border: none !important">No transaction found</label>
            </div>-->

          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  import { docFooter } from '../../utils/common'
  import Header from '../../components/Header';
  import LoaderItem from '../../components/LoaderItem'
  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'  
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';
  

  
  export default {
    name: 'HomePage',
    components: {
      Header,
      LoaderItem
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      permission: JSON.parse(localStorage.getItem('permission')),
      batches: [],
      batchesDatas: [],
      dateFrom: "",
      dateTo: "",
      criteria: "all",
      verifiedId: "0",
      createdId: "0",
      verification: "all",
      status: "all",
      stockId: "0",
      dataTable:  null,
      processedData: [],
      loading:false,
      batchNo: ""
    }),
    computed:{
      ... mapGetters (["getUsers", "getNogs"]),
      users () { return this.getUsers },
      nogs () { return this.getNogs },
    },
    mounted(){
      this.fetchUsers()
      this.fetchNogs()
      this.setupBatches()
    },
    methods:{
      ... mapActions(['fetchUsers', 'fetchNogs']),
      async setupBatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        try{
            this.loading = true
            const res = await fetch(process.env.VUE_APP_URL+'/batches', requestOptions)
            console.log(res)
            this.batches = await res.json()
            this.batchesDatas = this.batches
            //console.log(this.batches)
            $("#transactionsTable_wrapper").innerHTML=""
            this.processedData = this.preprocessData(this.batches);
            this.initializeDataTable();
        }catch(err){
            console.log(err)
        }finally{
            this.loading = false
        }
      },

      clear(){
        this.batchNo = ""
        this.dateFrom = ""
        this.dateTo = ""
        this.criteria = "all"
        this.verifiedId = "0"
        this.status = "all"
        this.verification = "all"
        this.createdId = "0"
        this.stockId = "0"
        this.verifiedId = "0"
        this.reinitializeTable()
      },

      reinitializeTable(){
        this.dataTable.clear()
        this.dataTable.rows.add(this.preprocessData(this.batchesDatas))
        this.dataTable.draw()
      },

      preprocessData(rawData) {
        return rawData.map(item => {
          let purchaseNo = "";
          let exception = "";
          let status =  "";
          let verification =  "";
          let stock = "";
          let handledBy = " - ";
          let action = "";

         if(item?.Purchase){
            purchaseNo = item.Purchase?.purchaseNo 
          }

          if(item?.Purchase){
            if(item?.Purchase?.exception == 0 ){
              exception = '<button class="btn btn-success btn-sm" style="margin-left: 5px; border-radius: 15px">OK</button>'
            } else  {
              exception = '<button class="btn btn-danger btn-sm" style="margin-left: 5px; border-radius: 15px  ">!</button>'
            }
          } else  {
              exception = '<button class="btn btn-success btn-sm" style="margin-left: 5px; border-radius: 15px">OK</button>'
            }

          if(item.isSplit == true && item.status != 'saved' && item.status != 'cancelled' && item.mergeId == null){
            status = '<button class="btn btn-sm btn-primary">Partial</button>';
          } else if(item.status == 'processed'){
            status = '<button class="btn btn-sm btn-success" style="border-radius: 15px !important">Processed</button>'
          } else if(item.status == 'cancelled'){
            status = '<button class="btn btn-sm btn-danger" style="border-radius: 15px !important">Cancelled</button>'
          } else{
            status = `<button class="btn btn-sm btn-warning" style="text-transform: capitalize; border-radius: 15px  ">${item.status}</button>`
          }

          if(item.isVerified == true){
            verification = '<button class="btn btn-success btn-sm" style="border-radius: 15px !important">Verified</button>'
          }else {
            verification = '<button class="btn btn-sm btn-warning" style="border-radius: 15px !important">Unverified</button>'
          }

          if(item.nogId) {
            stock = item.Nog?.nog
          } else {
            stock = ' - '
          }
          if(item.User) {
            handledBy = item.User.name
          }

          if(item.status == 'saved' && this.permission?.batchVerify){
            action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 15px;" href="/transaction/savedbatch/${item.id}">View</a>`
          }
          if(item.status == 'processed' && this.permission?.batchVerify){
            action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 15px;" href="/transaction/processedbatch/${item.id}">View</a>`
          }
          action += `<a class="btn btn-sm btn-default" style="border: 1px solid black; margin-right: 15px;" href="/transaction/pdfbatch/${item.id}">BPN PDF</a>`

          return {
            indexNo:"1",
            dateCreated: this.formatDate(item.createdAt),
            referenceNo:item.batchNo,
            purchaseNo,
            exception,
            status,
            verification,
            stock,
            handledBy,
            action
          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#transactionsTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
              
            },
            {
              extend: 'pdfHtml5',
              text: 'Export PDF',
              className: 'btn btn-primary',
              orientation: 'landscape', 
              exportOptions: {
                columns: ':visible', 
              },
              customize: this.customizePDF

              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'dateCreated' },
              { data: 'referenceNo' },
              { data: 'purchaseNo' }, 
              { data: 'exception' }, 
              { data: 'status' }, 
              { data: 'verification' }, 
              { data: 'stock' }, 
              { data: 'handledBy' }, 
              { data: 'action' }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },


      
      customizePDF(doc) {
      
      const profile = JSON.parse(localStorage.getItem('profile'))
      console.log(profile)
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#000000', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Stock Transfers',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                  }
                ]
              }
            ],
            fillColor: '#000000'
          };
        },

        doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    },


      async search(){
        if(this.batchNo == "" || this.batchNo == null)
          this.batchNo = "All"

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                criteria: this.criteria,
                verifiedId: this.verifiedId,
                createdId: this.createdId,
                verification: this.verification,
                status: this.status,
                stockId: this.stockId,
                batchNo: this.batchNo
            })
        };
        
        const res = await fetch(process.env.VUE_APP_URL+'/batches/search', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.batches = data.batches
            this.dataTable.clear();
            this.dataTable.rows.add(this.preprocessData(this.batches));
            this.dataTable.draw();
        
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: data.message
            })
        }
      },
    },
    beforeRouteLeave() {
      if (this.dataTable && typeof this.dataTable.destroy === 'function' && this.dataTable.parentNode) {
        this.dataTable.destroy();
      }
    }

  }
  </script>