<template>
    <div class="home">
  
    <Header :mainHeader="true"/>

    <section id="settings-users">
        <div class="container">
            <h4 class="mb-5">System Settings & Configurations – Manage User</h4>

            <div class="panel">
                <h5 for="" class="fw-bold text-primary">1. User Documents</h5>
                <div class="row mt-1">
                    <div class="col-md-4">
                        <label for="address">Passport Picture</label><br>
                        <img v-if="currentUploadPicture" :src="`${urlServer}/files/${currentUploadPicture}`" alt="" style="max-height: 225px; max-width: 600px;margin-bottom: 10px;">
                        <input type="file" class="form-control" ref="filePicture" @change="handleFileChange" placeholder="Upload a new file" style="float: left; width: 80%;">
                        <button class="btn btn-sm btn-primary" @click="postDocument('picture')" style="float: left; margin: 2px 0px 0px 10px;">Upload</button>
                    </div>
                    <div class="col-md-4">
                        <label for="address">User ID</label><br>
                        <img v-if="currentUpload" :src="`${urlServer}/files/${currentUpload}`" alt="" style="max-height: 225px; max-width: 600px;margin-bottom: 10px;">
                        <input type="file" class="form-control" ref="fileUpload" @change="handleFileChange" placeholder="Upload a new file" style="float: left; width: 80%;">
                        <button class="btn btn-sm btn-primary" @click="postDocument('upload')" style="float: left; margin: 2px 0px 0px 10px;">Upload</button>
                    </div>
                    <div class="col-md-4">
                        <label for="address">Signature</label><br>
                        <img v-if="currentUploadSignature" :src="`${urlServer}/files/${currentUploadSignature}`" alt="" style="max-height: 225px; max-width: 600px;margin-bottom: 10px;">
                        <input type="file" class="form-control" ref="fileSignature" @change="handleFileChange" placeholder="Upload a new file" style="float: left; width: 80%;">
                        <button class="btn btn-sm btn-primary" @click="postDocument('signature')" style="float: left; margin: 2px 0px 0px 10px;">Upload</button>
                    </div>
                </div>
                <hr>
                <h5 for="" class="fw-bold text-primary">2. Edit user</h5>
                <div class="row mt-1">
                  
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="address">System Role</label>
                        <select v-model="roleId" class="full form-control" id="roles"  v-if="roles.length > 0">
                            <option v-for="role in roles" v-bind:Key="role.id" :value="role.id">
                                {{ role.name }}
                            </option>
                        </select>
                        <div v-else>
                            <h5 for="" style="color: red !important;">No Role Found.</h5> 
                            <button class="btn btn-primary btn-sm" @click="this.fetchRoles">Fetch Roles</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                        <label for="">Name</label>
                        <input type="text"  class="form-control" v-model="name" >
                    </div>
                    <div class="col-md-3">
                        <label for="">Designation</label>
                        <input type="text"  class="form-control" v-model="designation" >
                    </div>
                    <div class="col-md-3">
                        <label for="">Employee Id No.</label>
                        <input type="text"  class="form-control" v-model="employeeId" >
                    </div>
                    <div class="col-md-3">
                        <label for="">Mobile Contact No. 1</label>
                        <input type="text" placeholder="Use +2547... (format)"  class="form-control" v-model="phone" >
                    </div>
                    <div class="col-md-3">
                        <label for="">Mobile Contact No. 2</label>
                        <input type="text" placeholder="Use +2547... (format)"  class="form-control" v-model="phone2" >
                    </div>
                    <div class="col-md-3">
                        <label for="">Email Address</label>
                        <input type="email"  class="form-control" v-model="email" >
                    </div>
                    <div class="col-md-2">
                        <div class="check-button">
                        <div class="form-group">
                            <label>System User?</label>
                            <div class="components-button">
                        <span class="switcher switcher-1">
                            <input type="checkbox" id="switcher-1" v-model="isSystemUser">
                            <label for="switcher-1"></label>
                        </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-1" style="margin-left:-20px">
                        <button class="btn btn-style btn-small" @click="postUser" style="margin-top: 23px;">Update</button>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../components/Header';
export default {
    name: 'SettingsUser',
    components:{
        Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        userId:"",
        user:"",
        name: "",
        email: "",
        phone: "",
        roleId:"",
        isSystemUser:true,
        designation:"",
        employeeId : "",
        phone2:"",
        uploadSignature:"",
        uploadPicture:"",
        upload:"",
        currentUploadSignature:"",
        currentUploadPicture:"",
        currentUpload:"",
        loading: false
    }),
    computed:{
        ... mapGetters (["getRoles"]),
        roles () { return this.getRoles },
    },
    methods:{
        ... mapActions(['fetchRoles']),
        async postUser () {
            let formData = new FormData()
            formData.append("name", this.name)
            formData.append("upload", this.upload)
            formData.append("picture", this.uploadPicture)
            formData.append("signature", this.uploadSignature)
            formData.append("designation", this.designation)
            formData.append("employeeId", this.employeeId)
            formData.append("isSystemUser", this.isSystemUser)
            formData.append("roleId", this.roleId)
            formData.append("email", this.email)
            formData.append("phone", this.phone)
            formData.append("phone2", this.phone2)
            const requestOptions = {
                method: "POST",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
                body:formData
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/'+this.userId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User Details updated',
                    text: data.message
                })
                this.$router.back()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not updated',
                    text: data.message
                })
            }
        },
        async postDocument (type) {
            let formData = new FormData()
            formData.append("name", this.name)
            if(type =="upload") {
             formData.append("file", this.upload)
            }
            if(type =="picture") {
            formData.append("file", this.uploadPicture)
            }
            if(type =="signature") {
            formData.append("file", this.uploadSignature)
            }
            const requestOptions = {
                method: "POST",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
                body:formData
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/'+this.userId+'/'+type, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'User Documents updated',
                    text: data.message
                })
                this.setupUser(this.userId)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not updated',
                    text: data.message
                })
            }
        },
        async setupUser(itemId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/'+itemId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user = data.user;
                this.userId= data.user.id;
                this.name= data.user.name;
                this.email= data.user.email;
                this.phone= data.user.phone;
                this.roleId= data.user.permissionId;
                this.isSystemUser= data.user.isSystemUser;
                this.designation= data.user.designation;
                this.employeeId= data.user.employeeId;
                this.phone2= data.user.phone2;
                this.currentUploadSignature= data.user.signature;
                this.currentUploadPicture= data.user.picture;
                this.currentUpload= data.user.uploadId;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'User not found. Try again',
                    text: data.message
                })
            }
        },

        handleFileChange() {
          this.upload = this.$refs.fileUpload.files[0];
          this.uploadSignature = this.$refs.fileSignature.files[0];
          this.uploadPicture = this.$refs.filePicture.files[0];
        }
        
    },
    mounted(){
        this.fetchRoles()
        this.userId = this.$route.params.id
        this.setupUser(this.userId)
    }
}
</script>

<style lang="scss" scoped>
input {
    border: 1px solid #b4c7e7;
    border-radius: 3px;
}
::placeholder{
    color: black !important;
    opacity: .7 !important;
    text-transform: none !important;
}
table{
    margin-left: 12px;
}
th, td{
    text-align: center !important;
}

$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>