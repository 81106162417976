<template>
    <div class="home">
  
    <Header :mainHeader="true"/>

    <section id="settings-categories">
        <div class="container">
            <h4 class="mb-5 fw-bold">System Configurations - Categories Setup</h4>

<div class="panel">
    <label for="">1. Units of Measure (Unit)</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter unit" v-model="unit" >
        </div>
        <div class="col-md-8">
            <button class="btn btn-primary btn-small" @click="postUnits">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="unitDisplay='block', this.fetchUnits()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': unitDisplay }">
        <h5>Unit of Measure list <button class="btn btn-sm btn-default pull-right text-primary" @click="unitDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Unit </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(unit,index) in units" v-bind:key="unit.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ unit.unit}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deleteUnit(unit.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
        
    </div>
    
</div>

<div class="panel">
    <label for="">2. Sub Units of Measure</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <select v-model="unitId" class="form-control"  v-if="units.length > 0">
                <option v-for="unit in units" v-bind:Key="unit.id" :value="unit.id">
                    {{ unit.unit }}
                </option>
            </select>
            <div v-else>
                <label for="" style="color: red !important;">No Unit Found.</label> 
                <button class="btn btn-primary btn-sm" @click="this.fetchUnits">Fetch Units</button>
            </div>
        </div>
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter sub unit" v-model="subUnit" >
        </div>
        <div class="col-md-2">
            <input type="number" min="0"  class="form-control" placeholder="Enter sub unit count" v-model="unitCount" >
        </div>
        <div class="col-md-4">
            <button class="btn btn-primary btn-small" @click="postSubUnits">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="SubUnitDisplay='block', this.fetchSubUnits()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': SubUnitDisplay }">
        <h5>Sub Unit of Measure list <button class="btn btn-sm btn-default pull-right text-primary" @click="SubUnitDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Unit </th>
                <th>Sub Unit </th>
                <th>Count </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(subUnit,index) in subUnits" v-bind:key="subUnit.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ subUnit.subunit?.unit}}</td>
                    <td>{{ subUnit.unit}}</td>
                    <td>{{ subUnit.count}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deleteUnit(subUnit.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>

<div class="panel">
    <label for="">3. Product Category</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter category" v-model="category" >
        </div>
        <div class="col-md-8">
            <button class="btn btn-primary btn-small" @click="postCategories">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="categoryDisplay='block', this.fetchCategories()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': categoryDisplay }">
        <h5>Product Categories (Nog) list <button class="btn btn-sm btn-default pull-right text-primary" @click="categoryDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Category </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(category,index) in categories" v-bind:key="category.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ category.category}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deleteCategory(category.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>

<div class="panel">
    <label for="">4. Product Sub Category</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <select v-model="categoryId" class="form-control"  v-if="categories.length > 0">
                <option v-for="category in categories" v-bind:Key="category.id" :value="category.id">
                    {{ category.category }}
                </option>
            </select>
            <div v-else>
                <label for="" style="color: red !important;">No Product Category.</label> 
                <button class="btn btn-primary btn-sm" @click="this.fetchCategories">Fetch Product Categories</button>
            </div>
        </div>
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter sub category" v-model="subCategory" >
        </div>
        <div class="col-md-6">
            <button class="btn btn-primary btn-small" @click="postSubCategories">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="SubCategoryDisplay='block', this.fetchSubCategories()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': SubCategoryDisplay }">
        <h5>Product Sub Categories list <button class="btn btn-sm btn-default pull-right text-primary" @click="SubCategoryDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Category </th>
                <th>Sub Category </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(category,index) in subcategories" v-bind:key="category.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ category.subcategory.category}}</td>
                    <td>{{ category.category}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deleteCategory(category.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>

<div class="panel">
    <label for="">5. Payment Methods</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter payment" v-model="payment" >
        </div>
        <div class="col-md-8">
            <button class="btn btn-primary btn-small" @click="postPayment">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="paymentsDisplay='block', this.fetchPayments()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': paymentsDisplay }">
        <h5>Payments list <button class="btn btn-sm btn-default pull-right text-primary" @click="paymentsDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Payment </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(payment,index) in payments" v-bind:key="payment.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ payment.payment}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deletePayment(payment.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="panel">
    <label for="">6. Purposes</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <select v-model="moduleId" class="form-control">
                <option value="Dispatch">Dispatch</option>
                <option value="Pickup">Pickup</option>
            </select>
        </div>
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter purpose" v-model="purpose" >
        </div>
        <div class="col-md-6">
            <button class="btn btn-primary btn-small" @click="postPurpose">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="purposesDisplay='block', this.fetchPurposes()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': purposesDisplay }">
        <h5>Purposes list <button class="btn btn-sm btn-default pull-right text-primary" @click="purposesDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Module / Feature </th>
                <th>Purpose </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(purpose,index) in purposes" v-bind:key="purpose.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ purpose.module}}</td>
                    <td>{{ purpose.purpose}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deletePurpose(purpose.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="panel">
    <label for="">7. Stock Category</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter stock category" v-model="nog" >
        </div>
        <div class="col-md-8">
            <button class="btn btn-primary btn-small" @click="postNog">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="nogsDisplay='block', this.fetchNogs()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': nogsDisplay }">
        <h5>Stock Category list <button class="btn btn-sm btn-default pull-right text-primary" @click="nogsDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Stock Category </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(nog,index) in nogs" v-bind:key="nog.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ nog.nog}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deleteNog(nog.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="panel">
    <label for="">8. Tags</label>
    <div class="row mt-2">
        <div class="col-md-2">
            <input type="text"  class="form-control" placeholder="Enter tag" v-model="tag" >
        </div>
        <div class="col-md-8">
            <button class="btn btn-primary btn-small" @click="postTag">Add</button>
        </div>
        <div class="col-md-2">
            <button class="btn-small btn btn-default" style="border: 1px solid black; float: right;" @click="tagsDisplay='block', this.fetchTags()">View List</button>
        </div>
    </div>

    <div class="row mt-3" :style="{ 'display': tagsDisplay }">
        <h5>Tags list <button class="btn btn-sm btn-default pull-right text-primary" @click="tagsDisplay='none'" style="float:right;">Hide List</button></h5>
        <table class="table table-hover">
            <thead>
                <th>No.</th>
                <th>Tag </th>
                <th>Action</th>
            </thead>
            <tbody>
                <tr v-for="(tag,index) in tags" v-bind:key="tag.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ tag.tag}}</td>
                    <td><button class="btn-sm btn btn-danger" @click="deleteTag(tag.id)">Delete</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div style="margin-bottom: 70px;"></div>
        </div>
    </section>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../components/Header';
export default {
    name: 'SettingsCategoryPage',
    components:{
        Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        typesObservation:[
            {category: "Positive", id: "1"},
            {category: "Negative", id: "0"}
        ],
        unit: "",
        unitId:"",
        unitCount: "",
        symbol: "",
        unitDisplay: "none",
        subUnit: "",
        subSymbol: "",
        SubUnitDisplay: "none",
        category: "",
        categoryDisplay: "none",
        subCategory: "",
        categoryId:"",
        SubCategoryDisplay: "none",
        payment:"",
        paymentsDisplay: "none",
        purpose: "",
        moduleId: "",
        purposesDisplay: "none",
        tag: "",
        tagsDisplay: "none",
        nog: "",
        nogsDisplay: "none",
    }),
    computed:{
        ... mapGetters (["getUnits", "getSubUnits", "getCategories", "getSubCategories", "getPayments", "getPurposes", "getTags", "getNogs"]),
        units () { return this.getUnits },
        subUnits () { return this.getSubUnits },
        categories () { return this.getCategories },
        subcategories () { return this.getSubCategories },
        payments () { return this.getPayments },
        purposes () { return this.getPurposes },
        tags () { return this.getTags },
        nogs () { return this.getNogs }
    },
    methods:{
        ... mapActions(['fetchUnits','fetchSubUnits', 'fetchCategories', 'fetchSubCategories','fetchPayments','fetchPurposes','fetchTags','fetchNogs']),
        async postUnits () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    type: "unit",
                    unit: this.unit,
                    symbol: "none",
                    description: ""
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/units', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.unitDisplay = 'block';
                this.unit = "";
                this.fetchUnits();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Unit not added',
                    text: data.message
                })
            }
        },
        async deleteUnit (id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/units/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Unit deleted successfully',
                    text: 'Unit account deleted'
                })
                this.fetchUnits();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Unit not deleted',
                    text: data.message
                })
            }
        },
        async postSubUnits () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    type: "subunit",
                    unit: this.subUnit,
                    symbol: "none",
                    description: "",
                    unitId: this.unitId,
                    count: this.unitCount
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/units/subunit', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.SubUnitDisplay = 'block';
                this.subUnit = "",
                this.unitCount = ""
                this.fetchSubUnits();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Unit not added',
                    text: data.message
                })
            }
        },
        async postCategories () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    type: "category",
                    category: this.category,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/category', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.categoryDisplay = "block";
                this.category = "";
                this.fetchCategories();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product Category not added',
                    text: data.message
                })
            }
        },
        async deleteCategory (id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/category/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Product Category deleted successfully',
                    text: 'Product Category account deleted'
                })
                this.fetchCategories();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product Category not deleted',
                    text: data.message
                })
            }
        },
        async postSubCategories () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    type: "subcategory",
                    category: this.subCategory,
                    categoryId: this.categoryId,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/category', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.SubCategoryDisplay = "block";
                this.subCategory = "";
                this.fetchSubCategories();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Product Sub Category not added',
                    text: data.message
                })
            }
        },
        async postPayment () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    payment: this.payment,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/payments', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.payment = "";
                this.paymentsDisplay = "block"
                this.fetchPayments();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Payment not added',
                    text: data.message
                })
            }
        },
        async deletePayment (id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/payments/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Payment deleted successfully',
                    text: 'Payment account deleted'
                })
                this.fetchCategories();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Payment not deleted',
                    text: data.message
                })
            }
        },
        async postPurpose () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    module: this.moduleId,
                    purpose: this.purpose,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purposes', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.purposesDisplay = "block"
                this.purpose = ""
                this.fetchPurposes();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purpose not added',
                    text: data.message
                })
            }
        },
        async deletePurpose (id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/purposes/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Purpose deleted successfully',
                    text: 'Purpose account deleted'
                })
                this.fetchCategories();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Purpose not deleted',
                    text: data.message
                })
            }
        },
        async postTag () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    tag: this.tag,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/tags', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.tag = "";
                this.tagsDisplay = "block"
                this.fetchTags();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Tag not added',
                    text: data.message
                })
            }
        },
        async deleteTag (id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/tags/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Tag deleted successfully',
                    text: 'Tag account deleted'
                })
                this.fetchTags();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Tag not deleted',
                    text: data.message
                })
            }
        },
        async postNog () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    nog: this.nog,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/nogs', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.nogsDisplay = "block"
                this.nog = ""
                this.fetchNogs();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Stock Category not added',
                    text: data.message
                })
            }
        },
        async deleteNog (id) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/nogs/delete/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Nog deleted successfully',
                    text: 'Nog account deleted'
                })
                this.fetchNogs();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Nog not deleted',
                    text: data.message
                })
            }
        },
    }
}
</script>

<style lang="css" scoped>
input {
    border: 1px solid black;
}

::placeholder{
    color: black !important;
    opacity: .7 !important;
    text-transform: none !important;
}
table{
    margin-left: 12px;
}
</style>