<template>
    <div class="transaction">
  
    <Header :mainHeader="true"/>

    <section id="transaction-products">
        <div class="container">
            <h4 class="mb-3 fw-bold">System Settings & Configurations – Roles & Permissions</h4>
            
            <h5 for="" class="text-primary">Add Role</h5>
            <div class="panel">
                
                <div class="row mt-2">
                    <div class="col-md-3">
                        <label for="">Role </label>
                        <input type="text"  class="form-control" v-model="name" >
                    </div>
                    <div class="col-md-5">
                        <label for="">Description</label>
                        <input type="text"  class="form-control" v-model="description" >
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-primary btn-small" @click="postRole" style="margin-top: 27px;">Create</button>
                    </div>
                </div>
            </div>
            <div class="purchases-list">
                <div class="row mt-3" style="padding: 0px 15px;">
                    <h5 style="padding-left: 0px;" class="text-primary">Roles List</h5>
                    <table class="table table-hover" >
                        <thead>
                            <th>No.</th>
                            <th>Role </th>
                            <th>Description </th>
                            <th>No. of Users </th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(role,index) in roles" v-bind:key="role.id">
                                <td>{{ index+1 }}</td>
                                <td>{{ role.name}}</td>
                                <td>{{ role.description}}</td>
                                <td v-if="role.users">{{ role.users.length}}</td>
                                <td v-else>0</td>
                                <td>
                                    <button class="btn-sm btn btn-primary" @click="$router.push({name: 'SettingsRole', params:{ id: role.id}})">Edit</button>
                                    <button class="btn-sm btn btn-danger" style="margin-left:5px;">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </section>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import Header from '../../components/Header';
export default {
    name: 'SettingsRoles',
    components:{
        Header
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        name: "",
        description: "",
    }),
    computed:{
        ... mapGetters (["getRoles"]),
        roles () { return this.getRoles },
    },
    methods:{
        ... mapActions(['fetchRoles']),
        async postRole () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    description: this.description,
                    name: this.name,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/permissions', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.fetchRoles();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Role not created',
                    text: data.message
                })
            }
        },
        
    },
    mounted(){
        this.fetchRoles()
    }
}
</script>

<style lang="css" scoped>

</style>