<template>
  <div class="home">

    <Header :mainHeader="true"/>

    <section id="warehouse-inventory">
      <div class="container" >
        <div class="row">
          <div class="col-md-6">
            <h5 class="fw-bold" style="font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #002060">Inventory & Warehouse Overview</h5>
          </div>
        </div>

        <div class="warehouse-inventory-card-wrapper" style="margin-top: 10px;">
          <div class="warehouse-inventory-card">
            <p>No. of Stock
              Transactions Today</p>
              <strong style="font-size: 25px !important;">{{ countTodayValues(batches) }}</strong>
            <div class="total">
              <div href="#" >
                <span :class="transactionRate > 0 ? 'text-success' : 'text-danger'">{{ transactionRate}} % </span>
                <svg
                v-if="transactionRate > 0"
                class="fill-meta-3"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="green"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                  fill="green"
                />
              </svg>

              <svg
                  v-else
                  class="fill-meta-5"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="#FF0000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                    fill="#FF0000"
                  />
                </svg>
                <span :class="transactionRate > 0 ? 'text-success' : 'text-danger'" > than yesterday</span>

              </div>    
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Warehouse Dispatch Orders
              Received Today</p>
              <strong style="font-size: 25px !important;">{{  countTodayValues(dispatches) }}</strong>
            <div class="total">
              <a href="#">
                  <span :class="dispatchesRate > 0 ? 'text-success' : 'text-danger'">{{  dispatchesRate }} % </span>
                  <svg
                    v-if="dispatchesRate > 0"
                    class="fill-meta-3"
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                   >
                    <path
                      d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                      fill="green"
                    />
                  </svg>

                  <svg
                    v-else
                    class="fill-meta-5"
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="#FF0000"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                      fill="#FF0000"
                    />
                  </svg>
                  <span :class="dispatchesRate > 0 ? 'text-success' : 'text-danger'"> than yesterday</span>
              </a>
              <!--
                <strong style="font-size: 25px !important;">{{  countTodayValues(dispatches) }}</strong>
              -->
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Warehouse Orders
              Processed Today</p>
            <strong style="font-size: 25px !important;">{{ getTodayStats() }}</strong>
            <div class="total">
              <div>
                <span :class="ordersRate > 0 ? 'text-success' : 'text-danger'">{{ ordersRate}} % </span>
                <svg
                  v-if="ordersRate > 0"
                  class="fill-meta-3"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="green"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                    fill="green"
                  />
                </svg>

                <svg
                  v-else
                  class="fill-meta-5"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="#FF0000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                    fill="#FF0000"
                  />
                </svg>

                <span :class="ordersRate > 0 ? 'text-success' : 'text-danger'"> than yesterday</span>

              </div>
              <!--
              <strong style="font-size: 25px !important;">{{ getTodayStats() }}</strong>
              -->
            </div>
          </div>


          


          <div class="warehouse-inventory-card">
            <p>BPN Exceptions This Month</p>
            <strong style="color: red; font-size: 25px !important;">  {{ mthExceptedBatches }} </strong>
            <div class="total">
              <a href="/transaction" class="text-sm text-primary">View more</a>
              <!--
                <div>
                <span :class="exceptionsRate > 0 ? 'text-success' : 'text-danger'">{{ exceptionsRate }} % </span>
                <svg
                  v-if="exceptionsRate > 0"
                  class="fill-meta-3"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="green"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                    fill="green"
                  />
                </svg>

                <svg
                  v-else
                  class="fill-meta-5"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="#FF0000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                    fill="#FF0000"
                  />
                </svg>

                <span :class="exceptionsRate > 0 ? 'text-success' : 'text-danger'"> than last month</span>
              </div>
              -->
              <!--
              <strong style="color: red; font-size: 25px !important;">  {{ mthExceptedBatches }} </strong>
              -->
              
          </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>POs Pending
              Approvals</p>
            <strong style="color: orange; font-size: 25px !important;">{{ unapprovedPos }}</strong>
            <div class="total">
              <a href="/purchases" class="text-sm text-primary">View more</a>
              <!--<strong style="color: orange; font-size: 25px !important;">{{ unapprovedPos }}</strong>-->
            </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Total Unverified Stocks</p>
            <strong style="color: red; font-size: 25px !important">{{ countUnverifiedStocks() }}</strong>
            <div class="total">
              <a href="/orders"  class="text-sm text-primary">View more</a>
              <!--<strong style="color: red; font-size: 27px !important">{{ countUnverifiedStocks() }}</strong>-->
            </div>
          </div>


          <div class="warehouse-inventory-card">
            <p>Scheduled
              Collections</p>
            <strong style="color: green; font-size: 25px !important">  {{ mthScheduledCollections }} </strong>
            <div class="total">
              <div>
                <span :class="collectionsRate > 0 ? 'text-success' : 'text-danger'">{{ collectionsRate}} % </span>
                <svg
                  v-if="collectionsRate > 0"
                  class="fill-meta-3"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="green"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                    fill="green"
                  />
                </svg>

                <svg
                  v-else
                  class="fill-meta-5"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="#FF0000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                    fill="#FF0000"
                  />
                </svg>
                <span :class="collectionsRate > 0 ? 'text-success' : 'text-danger'"> than last month</span>
              </div>
              <!--
              <strong style="color: green; font-size: 27px !important">  {{ mthScheduledCollections }} </strong>
              -->
              
          </div>
          </div>

          <div class="warehouse-inventory-card">
            <p>Warehouse Exceptions This Month</p>
            <strong style="color: red; font-size: 25px !important">  {{ mthExceptedOrders }} </strong>
            <div class="total">
              <a href="/orders" class="text-sm text-primary">View more</a>
              <!--
                   <div>
                <span :class="orderExceptionRate > 0 ? 'text-success' : 'text-danger'">{{ orderExceptionRate }} % </span>
                <svg
                  v-if="orderExceptionRate > 0"
                  class="fill-meta-3"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="green"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                    fill="green"
                  />
                </svg>

                <svg
                  v-else
                  class="fill-meta-5"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="#FF0000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                    fill="#FF0000"
                  />
                </svg>
                <span :class="orderExceptionRate > 0 ? 'text-success' : 'text-danger'"> than last month</span>
              </div>
              -->

              <!--
                 <strong style="color: red; font-size: 27px !important">  {{ mthExceptedOrders }} </strong>
              -->
             
             
          </div>
          </div>
          
        </div>


        
          <div class="row today-order-wrapper">
              <div class="col-md-4 align-self-center" style="height: 320px !important; overflow-y:auto">
              <h5 class="fw-bold" style="font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #002060">Today’s Orders (Dispatch & Pickup Notes)</h5>
              <div class="table-wrapper">
                <div class="today-table">
                  <div class="table-card">
                    <p><b>No</b></p>
                    <p><b>Time</b></p>
                    <p><b>Note</b></p>
                    <p><b>Status</b></p>
                    <p><b>Action</b></p>
                  </div>

                  <div v-for="(pickup, index) in todaysPickup" v-bind:key="pickup.id" class="table-card">
                    <p class="text-13">{{index + 1}}</p>
                    <p class="text-13">{{this.getTime(this.formatDate(pickup.createdAt, "true"))}}</p>
                    <p class="text-13">{{pickup.pickupNo}}</p>
                    <p class="text-13" v-if="pickup.status === 'pending'"><a href="#" class="text-13 btn btn-sm btn-warning" style="border-radius: 15px">Pending</a></p>
                    <p class="text-13" v-if="pickup.status === 'cancelled'"><a href="#" class="text-13 btn btn-sm btn-danger" style="border-radius: 15px">Cancelled</a></p>
                    <p class="text-13" v-if="pickup.status === 'active'"><a href="#" class="text-13" style="border-radius: 15px">Active</a></p>
                    <p class="text-13" v-if="pickup.status === 'completed'"><a href="#" class="text-13 btn btn-sm btn-success" style="border-radius: 15px">Completed</a></p>
                    <p class="text-13"><a :href="`/orders/pickup/pdf/${pickup.id}`" class="btn-style2 small text-13">View</a></p>
                  </div>
                  <div v-for="(dispatch, index) in todaysDispatch" v-bind:key="dispatch.id" class="table-card">
                    <p class="text-13" >{{ index + todaysPickup.length + 1 }}</p>
                    <p class="text-13" >{{this.getTime(this.formatDate(dispatch.createdAt, "true"))}}</p>
                    <p class="text-13" >{{dispatch.dispatchNo}}</p>
                    <p class="text-13"  v-if="dispatch.status === 'pending'"><a href="#" class="text-13 btn btn-sm btn-warning" style="border-radius: 15px">Pending</a></p>
                    <p class="text-13"  v-if="dispatch.status === 'cancelled'"><a href="#" class="text-13 btn btn-sm btn-danger" style="border-radius: 15px">Cancelled</a></p>
                    <p class="text-13"  v-if="dispatch.status === 'active'"><a href="#" class="text-13" style="border-radius: 15px">Active</a></p>
                    <p class="text-13"  v-if="dispatch.status === 'completed'"><a href="#" class="text-13 btn btn-sm btn-success" style="border-radius: 15px">Completed</a></p>
                    <p class="text-13" ><a :href="`/orders/dispatch/process/${dispatch.id}`" class="btn-style2 small text-13">View</a></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 align-self-center p-5">
              <h5 class="fw-bold" style="font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #002060">Weekly Stock Movement Analysis</h5>
              <div class="line-chart" >
                <LoaderItem v-if="chartLoader"/>
                <LineChart 
                    v-if="groupedPickups.length > 0 && groupedDispatches.length > 0"
                    :groupedBatches="groupedBatches"
                    :groupedOrders="groupedOrders"
                  />
              </div>
            </div>
            <div class="col-md-4 align-self-center">
              <h5 class="fw-bold" style="font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #002060">Stock Categories</h5>
              <div class="bar-chart">
                <LoaderItem v-if="barLoader"/>
                <BarChart
                  v-if="xValues.length > 0 && yValues.length > 0"
                  :xValues="xValues"
                  :yValues="yValues"
                
                />
              </div>
            </div>
          </div>
        
        <div class="system-user-manual bg-white">
          <div class="row">
            <div class="col-md-5 align-self-center">
              <h5 class="fw-bold">Transactions to monitor this month</h5>
              <ul class="lower-section">
                <li><p><strong>PO to review</strong> – {{unapprovedPos }}</p></li>
                <li><p><strong>Todays Orders</strong> – {{ getTodayStats()}} </p></li>
                <li><p><strong>Unverified Stocks</strong> – {{ countUnverifiedStocks() }} </p></li>
              </ul>
            </div>
            <div class="col-md-4 align-self-center">
              <h5 class="fw-bold">Stock Levels to monitor</h5>
              <ul class="lower-section">
                <li><p><strong class="green">Normal </strong>- {{  this.getStockLevelCount('Normal') }} items</p></li>
                <li><p><strong class="orange">Low </strong>- {{  this.getStockLevelCount('low') }} items</p></li>
                <li><p><strong class="">Re-order </strong>- {{  this.getStockLevelCount('order') }} items</p></li>
                <li><p><strong class="red">Out of Stock </strong>- {{  this.getStockLevelCount('out') }} items</p></li>
              </ul>
            </div>
            <div class="col-md-3 align-self-center">
              <div class="user-manual">
                <div class="img-wrapper">
                  <i class="fa-solid fa-circle-question"></i>
                </div>
                <h3>System user manual</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

import Header from '../components/Header';
import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';
import { mapGetters, mapActions } from 'vuex'
import { addArrays, countLastMonthValues, countThisMonthValues } from '@/utils/common';
import LoaderItem from '@/components/LoaderItem.vue';
import Swal from 'sweetalert2'

export default {
  name: 'HomePage',
  components: {
    Header,
    LineChart,
    BarChart,
    LoaderItem
  },

  data(){
    return{
      token: JSON.parse(localStorage.getItem('access_token')),
      date_now: new Date().getDate()+"/"+(new Date().getUTCMonth() + 1)+"/"+new Date().getFullYear()+"  "+new Date().getHours()+":"+new Date().getMinutes(),
      url: process.env.VUE_APP_URL,
      dispatches:[],
      pickups: [],
      todaysDispatch: [],
      todaysPickup: [],
      batches: [],
      nogs: [],
      unapprovedPos: 0,
      mthScheduledCollections: 0,
      collectionsRate: 0,
      exceptionsRate: 0,
      mthExceptedBatches: 0,
      mthExceptedOrders:0, 
      orderExceptionRate:0,
      groupedPickups: [], 
      groupedDispatches: [],
      groupedBatches: [],
      groupedOrders: [],
      weekDispatches: [],
      weekPickups: [],
      weekBatches: [],
      purchases: [],
      dispatchesRate:0.0, 
      ordersRate:0.0,
      transactionRate:0.0,
      activities: [],
      products: [],
      results: [],
      xValues: [],
      yValues: [],
      chartLoader: false,
      barLoader: false
    }
  },

  computed:{
        ... mapGetters (["getCategories", "getWarehouses", "getProductOptions, getProfile"]),
        categories () { return this.getCategories },
        warehouses () { return this.getWarehouses },
        profile() { return this.getProfile}
    },

  mounted(){
    this.setupPickups()
    this.getProducts()
    this.getActivities()
    this.getPurchases()
    this.setUpNogs()
    this.setupBatches()
    this.fetchCategories()
    this.fetchProfile()   
  },



  methods: {
    ... mapActions(['fetchNogs', 'fetchCategories', 'fetchProfile']),
    
    getTime(inputString) {
      const parts = inputString.split('-');
      return parts.length > 1 ? parts[1].trim() : '';
    },

    async setupBatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        try{
            this.loading = true
            const res = await fetch(process.env.VUE_APP_URL+'/batches', requestOptions)
            this.batches = await res.json()

            this.weekBatches = this.filterByCurrentWeek(this.batches)
            this.groupedBatches =  this.groupByDayOfWeek(this.weekBatches)
           // console.log("Week Batches",this.weekBatches)
           // console.log("Grouped Batches", this.groupedBatches)
            
            const exceptedBatches = this.batches.filter(item => item.exception === true);
            
            const mthExceptedBatches = countThisMonthValues(exceptedBatches)
            this.mthExceptedBatches = mthExceptedBatches
            const lastMthExceptedBatches =  countLastMonthValues(exceptedBatches)
            this.exceptionsRate =  Math.round(this.calculateRate(mthExceptedBatches,lastMthExceptedBatches))
          
            
            const todayBatches = this.countTodayValues(this.batches) + this.countTodayValues(this.batches)
            const yesterdayBatches = this.countYesterdayValues(this.batches) + this.countYesterdayValues(this.batches)
            this.transactionRate =  Math.round(this.calculateRate(todayBatches,yesterdayBatches))
            
        }catch(err){
            console.log(err)
        }
    },


    getProcessedOrders(orders) {
      return orders.filter(approval => approval.status === "completed");
    },

    async setUpNogs () {
      const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))}
      const res = await fetch(process.env.VUE_APP_URL+'/nogs',{ mode: 'cors', headers})
      this.nogs = await res.json()
    },

     async setupPickups(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups', requestOptions)
        this.pickups = await res.json()

        this.todaysPickup = this.getTodayValues(this.pickups)
        const scheduledCollections = this.pickups.filter(obj => obj.scheduled === 1);

        this.weekPickups = this.filterByCurrentWeek(this.pickups)
        this.groupedPickups =  this.groupByDayOfWeek(this.weekPickups)

        const thisMthCollections = countThisMonthValues(scheduledCollections)
        this.mthScheduledCollections = thisMthCollections
        const lastMthCollections = countLastMonthValues(scheduledCollections)

        this.collectionsRate = Math.round(this.calculateRate(thisMthCollections,lastMthCollections))
        this.setupDispatches()
      },


      async getPurchases () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
          const data = await res.json()
            if(data.status == true){
               this.purchases = data.purchases
               this.countUnapprovedPos()
              // console.log(this.purchases)
            }else{
               //console.log(data.message)
            }
      },


      countUnverifiedStocks(){
        const unverifiedPickups = this.pickups.filter(obj => obj.status === 'active');
        const unverifiedDispatches = this.dispatches.filter(obj => obj.status === 'active');
        return unverifiedPickups.length + unverifiedDispatches.length
      },


      countUnapprovedPos() {
        let count = 0;
        this.purchases.forEach(obj => {
            if (obj.status === 'pending') {
                count++;
            }
        });
        this.unapprovedPos = count
      },

      async setupDispatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };

        try {
          this.chartLoader = true
          const res = await fetch(process.env.VUE_APP_URL + '/dispatches', requestOptions);
          this.dispatches = await res.json();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while fetching the dispatches.',
          });
          this.chartLoader = false
        } finally {
          this.chartLoader = false
        }
                

        this.todaysDispatch= this.getTodayValues(this.dispatches)
        const todayDispatches = this.countTodayValues(this.dispatches)
        const yesterdayDispatches = this.countYesterdayValues(this.dispatches)
        

        this.dispatchesRate = Math.round(this.calculateRate(todayDispatches,yesterdayDispatches))
      
        const todayOrders = this.countTodayValues(this.getProcessedOrders(this.dispatches)) + this.countTodayValues(this.getProcessedOrders(this.pickups))
        const yesterdayOrders = this.countYesterdayValues(this.getProcessedOrders(this.dispatches)) + this.countYesterdayValues(this.getProcessedOrders(this.pickups))
        this.ordersRate =  Math.round(this.calculateRate(todayOrders,yesterdayOrders))

        this.weekDispatches =  this.filterByCurrentWeek(this.dispatches)
        this.groupedDispatches = this.groupByDayOfWeek(this.weekDispatches)
        this.groupedOrders = addArrays(this.groupedPickups, this.groupedDispatches)
       
        const exceptedPickups = this.batches.filter(item => item.exception == 1);
        const exceptedDispatches = this.batches.filter(item => item.exception == 1);
            
        const mthExceptedPickups = countThisMonthValues(exceptedPickups)
        const mthExceptedDispatches = countThisMonthValues(exceptedDispatches)

        const lastMthExceptedPickups =  countLastMonthValues(exceptedPickups)
        const lastMthExceptedDispatches =  countLastMonthValues(exceptedDispatches)

        const mthExceptedOrders = mthExceptedDispatches + mthExceptedPickups
        const lastMthExceptedOrders = lastMthExceptedDispatches + lastMthExceptedPickups

        this.mthExceptedOrders = mthExceptedOrders
        this.orderExceptionRate = Math.round(this.calculateRate(mthExceptedOrders, lastMthExceptedOrders))


            
      },
   

      calculateRate(todayOrders, yesterdayOrders){
        if(todayOrders-yesterdayOrders == 0)
          return 0
        else
          return ((todayOrders-yesterdayOrders)*100)/todayOrders
      },

      getTodayStats(){
        return this.countTodayValues(this.getProcessedOrders(this.dispatches)) + this.countTodayValues(this.getProcessedOrders(this.pickups))
      },


      getYesterdayStats(){
        return this.countYesterdayValues(this.dispatches) + this.countYesterdayValues(this.pickups)
      },

      isInCurrentWeek(date) {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
        const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
        
        startOfWeek.setHours(0, 0, 0, 0);
        endOfWeek.setHours(23, 59, 59, 999);
        
        return date >= startOfWeek && date <= endOfWeek;
      },

      filterByCurrentWeek(data) {
          const currentWeekData = data.filter(item => {
              const createdAt = new Date(item.createdAt);
              return this.isInCurrentWeek(createdAt);
          });
          return currentWeekData;
      },


      groupByCategory() {
        const grouped = {};
        this.products.forEach(obj => {
            const category = obj?.Category?.category;
            if (!grouped[category]) {
                grouped[category] = 0;
            }
            grouped[category]++;
        });
        const result = Object.entries(grouped).map(([category, count]) => ({
            category,
            count
        }));
        
        return result;
      },

    
      groupByDayOfWeek(array) {
        const counts = [0, 0, 0, 0, 0, 0, 0]
        array.forEach(item => {
          const dayOfWeek = new Date(item.createdAt).getDay();
          counts[dayOfWeek]++;
        });
        const result = [];
        for (let i = 1; i <= 7; i++) {
          const dayIndex = (i + 5) % 7;
          result.push(counts[dayIndex] || 0);
        }
        return result;
    },


    async getProducts (){
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + this.token},
        };
        try {
          this.barLoader = true
          const res = await fetch(process.env.VUE_APP_URL + '/products', requestOptions);
          this.products = await res.json();
          this.results = this.groupByCategory();
          this.xValues = this.results.map(obj => obj.category);
          this.yValues = this.results.map(obj => obj.count);
        } catch (error) {
          this.barLoader = false
        }finally{
          this.barLoader = false
        }
      
     },

      async getActivities () {
          const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/inventory/activity', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.activities = data.activities
          }
      },


      getStockLevelCount(stock) {
        const stockLowerCase = stock.toLowerCase()
        let count = 0

        for (const obj of this.activities) {
            const objStockLowerCase = obj.stock.toLowerCase()
            if (objStockLowerCase === stockLowerCase) {
                count++;
            }
        }
        return count;
      },


      countTodayUpdatedValues(array) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 
        const todayValues = array.filter(obj => {
            const createdAtDate = new Date(obj.updatedAt);
            const createdAtString = createdAtDate?.toISOString().split('T')[0];
            return createdAtString === todayString;
        });
        return todayValues.length;
      },

      countYesterdayUpdatedValues(array) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1); // Set yesterday's date
        
        const yesterdayString = yesterday.toISOString().split('T')[0]; // Convert yesterday's date to string
        
        const yesterdayValues = array.filter(obj => {
            const createdAtDate = new Date(obj.updatedAt);
            const createdAtString = createdAtDate?.toISOString().split('T')[0];
            return createdAtString === yesterdayString;
        });
        return yesterdayValues.length;
      },

      countTodayValues(array) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 
        const todayValues = array.filter(obj => {
            const createdAtDate = new Date(obj.createdAt);
            const createdAtString = createdAtDate.toISOString().split('T')[0];
            return createdAtString === todayString;
        });
        return todayValues.length;
      },
      
      getTodayValues(array) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 
        const todayValues = array.filter(obj => {
            const createdAtDate = new Date(obj.createdAt);
            const createdAtString = createdAtDate.toISOString().split('T')[0];
            return createdAtString === todayString;
        });
        return todayValues;
      },

      countYesterdayValues(array) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1); // Set yesterday's date
        
        const yesterdayString = yesterday.toISOString().split('T')[0]; // Convert yesterday's date to string
        
        const yesterdayValues = array.filter(obj => {
            const createdAtDate = new Date(obj.createdAt);
            const createdAtString = createdAtDate.toISOString().split('T')[0];
            return createdAtString === yesterdayString;
        });
        return yesterdayValues.length;
      },

      countVerifiedPickups() {
        let count = 0;
        for (const obj of this.pickups) {
          if (!obj.isVerified) {
            count++;
          }
        }

        return count;
      }
  }
}
</script>